package uz.ferro.shop.model

import kotlinx.serialization.Serializable

@Serializable
data class MarketCategory(
    val id: Int? = null,
    val category: Category? = null,
    val sort: Int? = null
)
