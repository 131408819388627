package uz.ferro.shop.pages.category

import emotion.react.css
import mui.material.Box
import mui.material.Paper
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.useContext
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.Category
import uz.ferro.shop.model.PHOTO_SIZE_SMALL
import uz.ferro.shop.util.localized
import web.cssom.Cursor
import web.cssom.ObjectFit
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px

external interface CategoryItemViewProps : Props {
    var category: Category
}

val CategoryHorizontalListItemView = FC<CategoryItemViewProps> { props ->
    val category = props.category

    val navigator = useContext(NavigationContext)
    val locale = useContext(LocaleContext)

    fun openCategoryPage() {
        navigator.openCategory(category.id!!)
    }

    Paper {
        sx {
            width = 200.px
            height = 100.pct
            borderWidth = 1.px
            overflow = Overflow.hidden
            position = Position.relative
            flexShrink = number(0.0)
        }

        Box {
            sx {
                padding = 8.px
                cursor = Cursor.pointer
            }

            onClick = { openCategoryPage() }


            img {
                css {
                    width = 100.pct
                    height = 184.px
                    objectFit = ObjectFit.contain
                    cursor = Cursor.pointer
                }

                val photoUrl = category.getPhotoUrl(size = PHOTO_SIZE_SMALL)
                if (photoUrl.isNotBlank()) {
                    src = photoUrl
                }
            }

            Box {
                sx {
                    marginTop = 8.px
                    fontSize = 14.pt
                }
                +category.name.localized()
            }
        }
    }
}