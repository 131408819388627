package uz.ferro.shop.pages

import emotion.react.css
import mui.material.Badge
import mui.material.BadgeColor
import mui.material.Box
import mui.material.Button
import mui.material.Dialog
import mui.material.DialogActions
import mui.material.DialogContent
import mui.material.DialogTitle
import mui.material.IconButton
import mui.material.Stack
import mui.material.StackDirection
import mui.material.SvgIconSize
import mui.material.Typography
import mui.system.responsive
import mui.system.sx
import react.ChildrenBuilder
import react.FC
import react.Props
import react.ReactNode
import react.dom.html.ReactHTML.img
import react.router.dom.Link
import react.useContext
import react.useState
import uz.ferro.shop.CartContext
import uz.ferro.shop.Colors
import uz.ferro.shop.LocaleContext
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.locale.LanguageManager
import uz.ferro.shop.ui.search.SearchView
import uz.ferro.shop.model.CartManager
import uz.ferro.shop.model.CartProduct
import web.cssom.Auto
import web.cssom.Cursor
import web.cssom.Display
import web.cssom.FlexDirection
import web.cssom.FontWeight
import web.cssom.ObjectFit
import web.cssom.Position
import web.cssom.number
import web.cssom.pct
import web.cssom.pt
import web.cssom.px

fun MainWrapper(block: ChildrenBuilder.(props: Props) -> Unit = {}): FC<Props> {

    val childBlock = FC(block)

    return FC {
        val locale = useContext(LocaleContext)
        val navigator = useContext(NavigationContext)
        var addedToCartDialogState by useState(AddedToCartDialogState())
        val cartManagerCtx = useContext(CartContext)
        var cartCountState by useState(cartManagerCtx.cart.products.size)
        val language = LanguageManager.currentLanguage

        val marketCallback = object : MarketCallback() {
            override val cartManager: CartManager = cartManagerCtx

            override fun onAddedToCart(cartProduct: CartProduct) {
                addedToCartDialogState = AddedToCartDialogState(
                    isOpen = true,
                    cartProduct = cartProduct
                )
            }

            override fun onCartCountUpdated() {
                cartCountState = cartManager.cart.products.size
            }
        }

        fun closeAddedToCartDialog() {
            addedToCartDialogState = addedToCartDialogState.copy(isOpen = false)
        }

        MarketContext(marketCallback) {

            Box {
                sx {
                    height = 50.px
                    backgroundColor = Colors.headerBgColor
                    position = Position.relative
                }

                Box {
                    sx {
                        width = 100.pct
                        height = 100.pct
                        maxWidth = 1280.px
                        marginLeft = Auto.auto
                        marginRight = Auto.auto
                    }

                    img {
                        src = "./logo.png"
                        css {
                            height = 100.pct
                            cursor = Cursor.pointer
                        }
                        onClick = {
                            navigator.openMain()
                        }
                    }

                    Link {
                        css {
                            color = Colors.white
                            position = Position.absolute
                            right = 10.px
                            top = 20.px
                        }
                        to = "/admin"
                        +"Admin"
                    }
                }
            }

            Box {
                sx {
                    width = 100.pct
                    maxWidth = 1280.px
                    marginLeft = Auto.auto
                    marginRight = Auto.auto
                }

                Box {
                    sx {
                        height = 24.px
                    }
                }



                Stack {
                    direction = responsive(StackDirection.row)

                    Box {
                        sx {
                            flexGrow = number(1.0)
                            display = Display.flex
                            flexDirection = FlexDirection.row
                            paddingLeft = 16.px
                            paddingRight = 16.px
                        }

                        Box {
                            sx {
                                flexGrow = number(1.0)
                                paddingTop = 8.px
                            }

                            SearchView()
                        }

                        Box {

                            sx {
                                paddingLeft = 16.px
                                paddingRight = 16.px
                            }

                            IconButton {
                                if (cartCountState > 0) {
                                    Badge {
                                        color = BadgeColor.primary
                                        badgeContent = ReactNode(cartCountState.toString())
                                        showZero = false
                                        mui.icons.material.LocalGroceryStoreOutlined {
                                            htmlColor = Colors.primaryColorHex
                                            fontSize = SvgIconSize.large
                                        }
                                    }
                                } else {
                                    mui.icons.material.LocalGroceryStoreOutlined {
                                        htmlColor = Colors.primaryColorHex
                                        fontSize = SvgIconSize.large
                                    }
                                }

                                onClick = {
                                    navigator.openCart()
                                }
                            }
                        }
                    }
                }

                Box {
                    childBlock()
                }

                Box {
                    sx {
                        height = 100.px
                    }
                }
            }

            Dialog {
                fullWidth = true
                open = addedToCartDialogState.isOpen
                onClose = { _, _ -> closeAddedToCartDialog() }
                DialogTitle {
                    +locale.addedToCart
                }
                DialogContent {

                    Box {
                        sx {
                            padding = 16.px
                        }

                        val cartProduct = addedToCartDialogState.cartProduct

                        img {
                            css {
                                width = 100.pct
                                height = 200.px
                                objectFit = ObjectFit.contain
                                cursor = Cursor.pointer
                            }

                            val photoUrl = cartProduct?.product?.getPrimaryPhotoUrl().orEmpty()
                            if (photoUrl.isNotBlank()) {
                                src = photoUrl
                            }
                        }

                        Typography {
                            sx {
                                fontSize = 14.pt
                                fontWeight = FontWeight.bold
                                cursor = Cursor.pointer
                            }
                            +cartProduct?.product?.getLocalizedName()
                        }
                    }

                    DialogActions {
                        Button {
                            onClick = {
                                closeAddedToCartDialog()
                            }
                            +locale.continueShopping
                        }

                        Button {
                            onClick = {
                                closeAddedToCartDialog()
                                navigator.openCart()
                            }
                            +locale.goToCart
                        }
                    }
                }
            }
        }
    }
}

