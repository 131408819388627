package uz.ferro.shop.pages.category

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.useContext
import react.useEffectOnce
import react.useState
import uz.ferro.shop.NavigationContext
import uz.ferro.shop.model.Category
import uz.ferro.shop.util.localized
import uz.ferro.shop.util.noScrollBars
import web.cssom.Cursor
import web.cssom.Overflow
import web.cssom.pct
import web.cssom.pt
import web.cssom.px

external interface CategoryListProps : Props {
    var category: Category
}

val CategoryHorizontalList = FC<CategoryListProps> { props ->
    val navigator = useContext(NavigationContext)
    val category = props.category
    var subCategories by useState<List<Category>>(emptyList())

    useEffectOnce {
        MainScope().launch {
            subCategories = CategoryManager.getSubCategories(category.id!!)
        }
    }

    Box {
        Box {
            Box {
                sx {
                    marginTop = 16.px
                    fontSize = 18.pt
                    cursor = Cursor.pointer
                }

                onClick = {
                    navigator.openCategory(category.id!!)
                }

                +category.name.localized()
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(8.px)

                sx {
                    width = 100.pct
                    height = 280.px
                    padding = 4.px
                    overflowX = Overflow.scroll
                    overflowY = Overflow.hidden
                }

                noScrollBars()

                subCategories.forEach { subCategory ->
                    CategoryHorizontalListItemView {
                        this.category = subCategory
                    }
                }
            }
        }
    }
}