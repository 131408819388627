package uz.ferro.shop.api

import uz.ferro.shop.model.ProductGroupProduct
import uz.ferro.shop.util.orZero
import kotlin.js.Promise


private fun deleteProductGroupProduct(id: Int): Promise<Unit> {
    return delete("market-category/$id")
}

fun saveProductGroupProduct(pgp: ProductGroupProduct): Promise<ProductGroupProduct> {
    return if (pgp.id == null) {
        addProductGroupProduct(pgp)
    } else {
        updateProductGroupProduct(pgp)
    }
}

private fun addProductGroupProduct(productGroupProduct: ProductGroupProduct): Promise<ProductGroupProduct> {
    return post(path = "product-group-product", body = productGroupProduct)
}

private fun updateProductGroupProduct(productGroupProduct: ProductGroupProduct): Promise<ProductGroupProduct> {
    return put(path = "product-group-product", body = productGroupProduct)
}

fun getProductGroupProducts(productGroupId: Int): Promise<List<ProductGroupProduct>> {
    return get<List<ProductGroupProduct>>("product-group-product/group/${productGroupId}/list")
        .then { data ->
            data.sortedBy { it.sort.orZero() }
        }
}
