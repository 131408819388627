package uz.ferro.shop.api

import js.core.Void
import js.core.jso
import tanstack.query.core.QueryKey
import tanstack.react.query.useMutation
import tanstack.react.query.useQuery
import tanstack.react.query.useQueryClient
import uz.ferro.shop.MARKET_CATEGORIES_ALL_QUERY_KEY
import uz.ferro.shop.model.MarketCategory
import uz.ferro.shop.util.orZero
import kotlin.js.Promise

fun useAllMarketCategories(): List<MarketCategory> {
    val result = useQuery<List<MarketCategory>, Error, List<MarketCategory>, QueryKey>(
        queryKey = MARKET_CATEGORIES_ALL_QUERY_KEY,
        queryFn = { getAllMarketCategories() }
    )
    return result.data.orEmpty()
}

private fun addMarketCategory(marketCategory: MarketCategory): Promise<MarketCategory> {
    return post(path = "market-category", body = marketCategory)
}

private fun deleteMarketCategory(id: Int): Promise<Unit> {
    return delete(path = "market-category/$id")
}

typealias SaveMarketCategory = (MarketCategory) -> Unit
typealias DeleteMarketCategory = (Int) -> Unit

private fun updateMarketCategory(marketCategory: MarketCategory): Promise<MarketCategory> {
    return put(path = "market-category", body = marketCategory)
}

fun useSaveMarketCategory(): SaveMarketCategory {
    val client = useQueryClient()
    return useMutation<MarketCategory, Error, MarketCategory, QueryKey>(
        mutationFn = { mc ->
            if (mc.id.orZero() > 0) {
                updateMarketCategory(mc)
            } else {
                addMarketCategory(mc)
            }
        },
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(MARKET_CATEGORIES_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<SaveMarketCategory>()
}

fun useDeleteMarketCategory(): DeleteMarketCategory {
    val client = useQueryClient()
    return useMutation<Unit, Error, Int, QueryKey>(
        mutationFn = { id ->
            deleteMarketCategory(id)
        },
        options = jso {
            onSuccess = { _, _, _ -> client.invalidateQueries<Void>(MARKET_CATEGORIES_ALL_QUERY_KEY) }
        }
    ).mutate.unsafeCast<DeleteMarketCategory>()
}

fun getAllMarketCategories(): Promise<List<MarketCategory>> {
    return get<List<MarketCategory>>(path = "market-category/list")
        .then { data ->
            data.sortedBy { it.sort.orZero() }
        }
}
