package uz.ferro.shop.manager

import uz.ferro.shop.api.suspendGet
import uz.ferro.shop.api.suspendPost
import uz.ferro.shop.model.UserWarehouses
import uz.ferro.shop.model.Warehouse

object WarehouseManager {
    private const val API_PATH = "warehouse"

    suspend fun getList(): List<Warehouse> {
        return suspendGet<List<Warehouse>>("$API_PATH/list")
            .sortedBy { it.name }
    }

    suspend fun saveUserWarehouses(data: UserWarehouses): UserWarehouses {
        return suspendPost(path = "user-warehouse", body = data)
    }
}