package uz.ferro.shop.model

import kotlinx.serialization.Serializable
import uz.ferro.shop.util.getLocalized

@Serializable
data class SearchResult(
    val title: String,
    val titleCyrillic: String,
    val titleTranslation: String?,
    val referenceId: Long,
    val type: String? = null,
    val previewPhoto: DataFile? = null
) {

    fun getLocalizedName(): String = getLocalized(
        uzLat = title,
        uzCyr = titleCyrillic,
        ru = titleTranslation
    )
}
